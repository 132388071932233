import {
  BareCompany,
  InitializeReviewProcessResponse,
  StartReviewBody,
  StartReviewResponse,
  SystemType,
  UpdateReviewRequest,
  updateInviteRequest,
} from '../types/ApiTypes';
import { createAxiosInstance } from './AxiosService';
import {
  formatGetClientInviteFormInfoResponse,
  formatInitReviewResponse,
} from './ReviewProcessService';

export async function getClientInviteFormInfo(
  uniqueCompanyId: string | undefined,
  inviteId: string | undefined,
  setSystem:React.Dispatch<React.SetStateAction<SystemType | undefined>>
): Promise<BareCompany> {
  if (!uniqueCompanyId) {
    throw new Error('No company id was found');
  }

  return createAxiosInstance("")
    .get(`getClientInviteFormInfo/${uniqueCompanyId}/` + (inviteId ? "?inviteId="+inviteId:""))
    .then((response) => {
        if(response.data?.data?.jwtToken){
            localStorage.setItem('jwtToken', response.data.data.jwtToken)
            setSystem((prev)=>({...prev, jwtToken: response.data.data.jwtToken}));
        }
      return formatGetClientInviteFormInfoResponse(response.data.data);
    })
    .catch((error) => {
      throw error;
    });
}

export async function submitInviteForm(
    firstname: string,
    lastname: string,
    email: string,
    mobile: string,
    country_code: string,
    optin: boolean,
    isVideo: boolean,
    isText: boolean,
    recaptchaToken: string | null,
    inviteId: string | null,
    jwtToken: string
): Promise<string> {
  return createAxiosInstance(jwtToken)
        .post('submitInviteForm/', {
            firstname,
            lastname,
            email,
            mobile,
            country_code,
            optin,
            type: isVideo ? 'video' : isText? 'text' : null,
            recaptchaToken,
            inviteId
        })
        .then((response) => {
            return response.data.data.inviteId;
        })
        .catch((error) => {
            throw error;
        });
}

export async function submitReferralForm(
  firstname: string,
  lastname: string,
  email: string,
  mobile: string,
  country_code: string,
  recaptchaToken: string | null,
  jwtToken: string
): Promise<any> {
  return createAxiosInstance(jwtToken)
    .post('submitReferralForm/', {
      firstname,
      lastname,
      email,
      mobile,
      country_code,
      recaptchaToken,
    }).then((r)=>{
        return r.data
      })
    .catch((error) => {
      throw error;
    });
}

export async function initializeReviewProcess(
  inviteId: string | undefined
): Promise<InitializeReviewProcessResponse> {
  if (!inviteId) {
    throw new Error('No invite id was found');
  }
  return createAxiosInstance("")
    .get(`initializeReviewProcess/${inviteId}/`)
    .then((response) => {
      return formatInitReviewResponse(response.data.data);
    })
    .catch((error) => {
      throw error;
    });
}

export async function startReview(
  body: StartReviewBody,
  jwtToken:string
): Promise<StartReviewResponse> {
  return createAxiosInstance(jwtToken)
    .post('startReview/', body)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function updateReview(
  updatedReview: UpdateReviewRequest,
  jwtToken:string
): Promise<boolean> {
  return createAxiosInstance(jwtToken)
    .post('updateReviewInvite/', updatedReview)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function updateInvite({
  type,
  platform,
  jwtToken
}: updateInviteRequest): Promise<boolean> {
  return createAxiosInstance(jwtToken)
    .post('updateInvite/', { type, platform })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
}

export async function uploadVideoReview(
  reviewId: string,
  newVideo: Blob | File,
  stars: number,
  setProgress: React.Dispatch<
    React.SetStateAction<{ progress: number; estimated: number | undefined }>
  >,
  jwtToken: string,
  legalJargon?: string,
  timestamp?: number,
): Promise<boolean> {

  const fileName = Date.now().toString() + reviewId;

  const formData = new FormData();
  formData.append('reviewId', reviewId);
  formData.append('file', newVideo, fileName);
  formData.append('rating', stars.toString());

  if (legalJargon) {
    formData.append('legalJargon', legalJargon);
  }

  if (timestamp) {
    formData.append('timestamp', timestamp.toString());
  }

  return createAxiosInstance(jwtToken)
    .post('uploadVideoReview/', formData, {
      onUploadProgress: (progressEvent) =>
        setProgress({
          progress: progressEvent.progress ? progressEvent.progress : 0,
          estimated: progressEvent.estimated,
        }),
    })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
}
