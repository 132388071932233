import { useContext, useEffect, useRef, useState } from 'react';
import { CompanyContext } from '../contexts/CompanyContext';
import { InviteContext } from '../contexts/InviteContext';
import { ReviewContext } from '../contexts/ReviewContext';
import {
  afterReviewRatingPlatformClick,
  convertDomId,
  getReviewRatingStep,
} from '../services/ReviewProcessService';
import { ErrorState, StepState } from '../types/PropsTypes';
import { ThankYouPage } from '../components/ThankYouPage';
import { ReviewOptions } from '../components/ReviewOptions';
import { RatingSelect } from '../components/RatingSelect';
import {
  startReview,
  updateInvite,
  updateReview,
} from '../services/SoTellUsApiService';
import { STUWrittenReview } from '../components/STUWrittenReview';
import { STUVideoReview } from '../components/STUVideoReview';
import { StartReviewBody } from '../types/ApiTypes';
import { useDisclosure } from '@mantine/hooks';
import { Box, LoadingOverlay, Stack, Title } from '@mantine/core';
import { CompanyLogo } from '../components/CompanyLogo';
import { ErrorModal } from '../components/ErrorModal';
import { RatingError } from '../components/ErrorContents/RatingError';
import { WrittenError } from '../components/ErrorContents/WrittenError';
import DevToolbar from '../components/devtools/DevToolbar';
import NPSInput from '../components/NPSInput';
import { BackButton } from '../components/BackButton';
import { SystemContext } from '../contexts/SystemContext';

export function ReviewRating() {
  const { system } = useContext(SystemContext)
  const { company, setCompany } = useContext(CompanyContext);
  const { invite, setInvite } = useContext(InviteContext);
  const { review, setReview } = useContext(ReviewContext);
  const [isWrittenReview, setIsWrittenReview] = useState(
    invite?.type === 'video' ? false : true,
  );
  const [step, setStep] = useState<StepState>(
    invite ? getReviewRatingStep(invite, review) : 'platform',
  );
  const [visible, { open, close }] = useDisclosure(false);
  const [errorState, setErrorState] = useState<ErrorState>({
    is400: true,
    show: false,
  });
  const [margin, setMargin] = useState<string>();
  const refMainBox = useRef<HTMLDivElement|null>(null);

  const marginTopResponsive = () => {
    const MOBILE_PERCENTAGE = "0.27";
    const DESKTOP_PERCENTAGE = "0.10";
    if(refMainBox.current && step !== "written" && step !== "video" && step !== "thankYou"){
      const availableWhiteSpace = window.innerHeight - refMainBox.current.offsetHeight;
      const percentage = window.innerWidth < 550 ? MOBILE_PERCENTAGE: DESKTOP_PERCENTAGE;
      return `calc(${availableWhiteSpace}px * ${percentage})`;
    }
    return "0"
  }

  useEffect(()=>{
    setMargin(marginTopResponsive())
  },[step])

  const onPlatformSelect = (
    domId: string,
    url: string,
    type?: 'video' | 'text',
  ) => {
    open();
    if (type === 'video') {
      setIsWrittenReview(false);
    }
    if (invite) {
      setInvite({ ...invite, settings: { capture_review_platform: domId } });
    }
    updateInvite({ type: type, platform: convertDomId(domId),  jwtToken: (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? ""})

      .catch((error) => {
        //Won't handle 400 errors here, as we want to make sure the user gets to where they are supposed to even if their api call is unsuccessful
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        }
      })
      .finally(() => {
        const nextStep = afterReviewRatingPlatformClick(domId);
        if(nextStep === "thankYou"){
          if(url.length > 0 && navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1){
            const delay = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));
            delay(4000).then(() => {
              setStep(nextStep);
              close();
            });
            window.location.href = url;
          }
          else{
            setTimeout(() => {
              window.open(url, '_blank');
              setStep(nextStep);
              close();
            })
           
          }
        }else{
            setStep(nextStep);
            close();
        }
         
      });

  };

  const onRatingSelect = (rating: number) => {
    open();

    const startReviewBody: StartReviewBody = {
      rating: rating,
      platform: invite?.settings?.capture_review_platform
        ? invite.settings.capture_review_platform
        : undefined,
      type: invite?.type ? invite.type : undefined,
    };
    //Review rating: this is going to start the review, same as rating-review, could we add more parameters like type since they have already selected written/video
    startReview(startReviewBody, (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "")
      .then((data) => {
        setReview({ ...data, stars: rating });
        if (isWrittenReview) {
          setStep('written');
        } else {
          setStep('video');
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        } else {
          setErrorState({
            is400: true,
            show: true,
            title: 'Error',
            children: <RatingError />,
          });
        }
      })
      .finally(() => {
        close();
      });
  };

  const onWrittenReviewSubmission = (reviewText: string, stars: number, legalJargon : string = '') => {
    open();
    if (review) {
      setReview({ ...review, review: reviewText });
    }
    updateReview({
      reviewId: review?.reviewId ? review?.reviewId : '',
      platform: 'stu',
      rating: stars,
      type: 'text',
      reviewText: reviewText,
      ...(legalJargon && {
        legalJargon: legalJargon,
        timestamp: Math.floor(Date.now() / 1000), //unix timestamp
      }),
    },
    (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "")
      .then(() => {
        setStep('thankYou');
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setErrorState({
            is400: false,
            show: true,
          });
        } else {
          setErrorState({
            is400: true,
            show: true,
            title: 'Error',
            children: <WrittenError />,
          });
        }
      })
      .finally(() => {
        close();
      });
  };

  const onVideoReviewSubmission = (status: 200 | 401) => {
    if (status === 401) {
      setErrorState({ is400: false, show: true });
    } else {
      setStep('thankYou');
    }
  };

  if (company && invite) {
    return (
      <div ref={refMainBox} className="main-box" style={{marginTop: margin}}>
        <ErrorModal
          is400={errorState.is400}
          opened={errorState.show}
          title={errorState.title}
          children={errorState.children}
          onClose={() => {
            setErrorState({
              is400: true,
              show: false,
              title: undefined,
              children: undefined,
            });
          }}
        />
        <Box pos="relative" className="centered-stack">
          <LoadingOverlay
            visible={visible}
            overlayProps={{ radius: 'sm', blur: 2 }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', position: 'absolute', left: 0 }}>
              {(step === 'written' || step === 'video') && (
                <BackButton
                  setStep={setStep}
                  rating={review?.stars ? review.stars : 0}
                  minStars={company.minStarAllowExternal}
                  context="review-rating"
                />
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CompanyLogo name={company.name} logo={company.logo} />
            </div>
          </div>
          {step === 'platform' ? (
            <Stack w="100%">
              <Title className="header-text">
                Select below where you would like to
                <br />
                leave your review
              </Title>
              <ReviewOptions
                platformOptions={company.platformOptions}
                onSelect={onPlatformSelect}
              />
            </Stack>
          ) : step === 'rating' ? (
            <>
              {company.isNPS ? (
                <NPSInput
                  onSelect={onRatingSelect}
                  company={company}
                  invite={invite}
                />
              ) : (
                <RatingSelect
                  firstName={invite.firstname}
                  companyName={company.name}
                  onSelect={onRatingSelect}
                />
              )}
            </>
          ) : step === 'written' ? (
            <STUWrittenReview
                  rating={review?.stars ? review.stars : 0}
                  companyName={company.name}
                  onSubmit={onWrittenReviewSubmission} 
                  isHipaa={company.isHipaa}            />
          ) : step === 'video' ? (
            <STUVideoReview onSuccess={onVideoReviewSubmission} companyName={company.name} isHipaa={company.isHipaa} />
          ) : (
            <ThankYouPage />
          )}
        </Box>
        {process.env.NODE_ENV !== 'production' && (
          <DevToolbar
            step={step}
            ctx={'Review-Rating'}
            setStep={(r) => {
              setStep(r);
            }}
            company={company}
            setCompany={(e) => {
              setCompany(e);
            }}
          />
        )}
      </div>
    );
  }

  return <></>;
}
