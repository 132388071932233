import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyContext } from '../contexts/CompanyContext';
import { InviteContext } from '../contexts/InviteContext';
import { ReviewContext } from '../contexts/ReviewContext';
import { initializeReviewProcess } from '../services/SoTellUsApiService';
import {
  Company,
  InitializeReviewProcessResponse,
  Invite,
  Review,
} from '../types/ApiTypes';
import { RatingReview } from './RatingReview';
import { ReviewRating } from './ReviewRating';
import './ReviewProcess.css';
import { Loader } from '@mantine/core';
import { SystemContext } from '../contexts/SystemContext';

export function ReviewProcess(): JSX.Element {
  const { inviteId } = useParams();
  const [pullingInviteJson, setPullingInviteJson] = useState(true);
  const {setSystem} = useContext(SystemContext)

  //Provider states
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [invite, setInvite] = useState<Invite | undefined>(undefined);
  const [review, setReview] = useState<Review | undefined>(undefined);
  const mode = import.meta.env.MODE;

  useEffect(() => {
    window.scrollTo(0, 0);
    let invite_id = inviteId;
    //If their link ends with a period or any other punctuation, ignore..
    if(inviteId?.endsWith(".")){
      invite_id = inviteId?.slice(0,inviteId?.length-1);
    }else if(inviteId?.endsWith(",")){
      invite_id = inviteId?.slice(0,inviteId?.length-1);
    }else if(inviteId?.endsWith("?")){
      invite_id = inviteId?.slice(0,inviteId?.length-1);
    }else if(inviteId?.endsWith("!")){
      invite_id = inviteId?.slice(0,inviteId?.length-1)
    }

    initializeReviewProcess(invite_id)
      .then((data: InitializeReviewProcessResponse) => {
        if(data.jwtToken) {
          localStorage.setItem('jwtToken', data.jwtToken);
          setSystem((prev)=>({...prev, jwtToken: data.jwtToken!}));
        }
        setCompany(data.company);
        setInvite(data.invite);
        if (data.review) {
          setReview(data.review);
        }

        //NPS invite redirect.
        if (data.company && invite_id && data.company.isNPS) {
          redirectForNPS();
        }
      })
      .catch(() => {
        //Do nothing, if we are not pulling the invite and have no company then that invite was not found
      })
      .finally(() => {
        setPullingInviteJson(false);
      });
  }, []);

  function redirectForNPS() {
    if (mode == 'production') {
      window.location.href = 'https://sotellus.com/i/' + inviteId;
    } else {
      window.location.href = 'https://' + mode + '.sotellus.com/i/' + inviteId;
    }
  }

  if (pullingInviteJson) {
    return (
      <div className='centered-stack' style={{minHeight: '100vh'}}>
      <Loader size={50} />
      </div>
    );
  } else if (company && invite) {
    return (
        <CompanyContext.Provider value={{ company, setCompany }}>
          <InviteContext.Provider value={{ invite, setInvite }}>
            <ReviewContext.Provider value={{ review, setReview }}>
              {company.firstPage === 'rating-review' ? (
                <RatingReview />
              ) : (
                <ReviewRating />
              )}
            </ReviewContext.Provider>
          </InviteContext.Provider>
        </CompanyContext.Provider>
    );
  } else {
    return <div className="main-box">No invite was found</div>;
  }
}
