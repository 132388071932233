import { useContext, useEffect, useRef, useState } from 'react';
import { uploadVideoReview } from '../services/SoTellUsApiService';
import { RatingChangeBox } from './RatingChangeBox';
import { ReviewContext } from '../contexts/ReviewContext';
import {
  Button,
  FileButton,
  Flex,
  Modal,
  Progress,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { VideoErrorType, VideoReviewProps } from '../types/PropsTypes';
import { VideoError } from './ErrorContents/VideoError';
import {
  IconVideo,
  IconCircleCheck,
  IconVideoOff,
  IconRetry,
} from './icons/TablerIcons';
import { SystemContext } from '../contexts/SystemContext';

const streamOptions: MediaRecorderOptions = {
  mimeType: navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 ? 'video/mp4' : 'video/webm',
};

export function STUVideoReview({ onSuccess, companyName, isHipaa }: VideoReviewProps): JSX.Element {
  const { review } = useContext(ReviewContext);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const mediaStream = useRef<MediaStream | null>(null);
  const videoChunks = useRef<Blob[]>([]);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [videoErrorOpen, setVideoErrorOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [showLegal, setShowLegal] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [recording, setRecording] = useState(false);
  const [currentRating, setCurrentRating] = useState(
    review?.stars ? review.stars : 0,
  );
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number;
    estimated: number | undefined;
  }>({ progress: 0, estimated: undefined });
  const [error, setError] = useState<VideoErrorType>(undefined);
  const hipaa_content = isHipaa ? " By providing this review, I consent to "+ companyName +" using my comments on their website and other promotional materials. I understand that my review may be de-identified to protect my privacy, and I have the right to withdraw my consent at any time by contacting "+ companyName +"." : "";
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent,
  );

  const {system} = useContext(SystemContext)

  const legalJargon = `
    By submitting your review to SoTellUs, you authorize the unrestricted use of your review by SoTellUs and by the person/entity that requested the review. 
    You hereby grant the unrestricted, irrevocable, worldwide, royalty-free right and license to copyright and use, re-use, publish, and republish photos, 
    videos, and/or audio recordings of me or in which I may be included, intact or in part, in composite or distorted in character or form, without restriction 
    as to changes, for unlimited purposes (both commercial and non-commercial), and through any and all media and methods of distribution, now known or hereafter 
    developed. All rights of privacy and publicity are hereby waived, including the right to use my name with my photos and/or videos. ${hipaa_content}
  `;

  const constraints: MediaStreamConstraints = {
    audio: {
      echoCancellation: { ideal: true },
    },
    video: {
      width: { min: 640, ideal: 1280, max: 1920 },
      height: { min: 400, ideal: 720, max: 1080 },
      aspectRatio: 1.777777778,
      frameRate: { ideal: 30 },
      facingMode: { ideal: 'user' },
    },
  };
  useEffect(() => {
    if (isMobile) {
      setLoading(false);
    } else {
      setMediaStream();
    }
    return () => {
      mediaStream.current?.getTracks().forEach((track) => {
        if (track && track.readyState == 'live') {
          track.stop();
        }
      });
    };
  }, []);


  async function askPermission(constraints:MediaStreamConstraints) {
    try {
      const videoPermission = await navigator.permissions.query({
        name: 'camera',
      } as any);
      const audioPermission = await navigator.permissions.query({
        name: 'microphone',
      } as any);

      const permissionState = {
        video: videoPermission.state,
        audio: audioPermission.state,
      };
      if (
        permissionState.video === 'denied' ||
        permissionState.audio === 'denied'
      ) {
        setError('notFound');
        setVideoErrorOpen(true);
        return;
      }
      if (
        permissionState.video === 'prompt' ||
        permissionState.audio === 'prompt'
      ) {
        // Request permissions by trying to access the camera
        try {
          await navigator.mediaDevices.getUserMedia(constraints);
        } catch (error) {
          setError('permissions');
          setVideoErrorOpen(true);
          return;
        }
      }
    } catch (error) {
      console.error('Error checking camera permissions:', error);
      return 'prompt'; // Assume prompt if there's an error
    }
  }

  async function setMediaStream() {
    try {
      if(!isMobile){
        await askPermission(constraints);
      }

      const newMediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        setLoading(false);
        mediaStream.current = newMediaStream;
        videoRef.current.srcObject = newMediaStream;

        const newRecorder = new MediaRecorder(newMediaStream, streamOptions);
        mediaRecorder.current = newRecorder;

        mediaRecorder.current.onstart = () => {
          setRecording(true);
        };

        mediaRecorder.current.onstop = () => {
          const videoBlob = new Blob(videoChunks.current, {
            type: streamOptions.mimeType,
          });
          const videoUrl = URL.createObjectURL(videoBlob);

          videoChunks.current = [];
          if (videoRef.current) {
            videoRef.current.srcObject = null;
            videoRef.current.src = videoUrl;
          }

          setVideoBlob(videoBlob);
          setRecording(false);
        };

        mediaRecorder.current.ondataavailable = (event) => {
          videoChunks.current.push(event.data);
        };

        mediaRecorder.current.onerror = () => {
          setError('recording');
          setVideoErrorOpen(true);
        };
      }
    } catch (error) {
      // This is a problem if they remove permissions for the camera
      setError('permissions');
      setVideoErrorOpen(true);
    } finally {
      setLoading(false);
    }
  }

  async function startRecording(){
    await setMediaStream();
    mediaRecorder.current?.start();
  }

  const onSubmit = (newVideo: Blob | File) => {
  setIsUploading(true);
  let uploadPromise;

  if (isHipaa) {
    uploadPromise = uploadVideoReview(
      review?.reviewId ? review?.reviewId : '',
      newVideo,
      currentRating,
      setUploadProgress,
      (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "",
      legalJargon ? legalJargon : '',
      legalJargon ? Math.floor(Date.now() / 1000) : 0,// Unix timestamp
    );

  } else {
    uploadPromise = uploadVideoReview(
      review?.reviewId ? review?.reviewId : '',
      newVideo,
      currentRating,
      setUploadProgress,
      (localStorage.getItem('jwtToken') && localStorage.getItem('jwtToken')!.length > 0) ? localStorage.getItem('jwtToken')! : system?.jwtToken ?? "",
    );
  }

  uploadPromise
    .then(() => {
      onSuccess(200);
    })
    .catch((error) => {
      if (error.response?.status === 401) {
        onSuccess(401);
      } else {
        setError('upload');
        setVideoErrorOpen(true);
      }
    });
};

  const onMobileSubmit = (newVideo: File | null) => {
    if (!newVideo) {
      setError('notFound');
      setVideoErrorOpen(true);
    } else {
      setVideoBlob(newVideo);
      setRecording(false);
    }
  };

  const onCloseUploadModal = () => {};

  function handleRetake() {
    setVideoBlob(null);
    setRecording(false);
    videoRef.current!.srcObject= null;
    if(!isMobile) setMediaStream();
  }

  function UploadModal() {
    return (
      <Modal
        opened={Boolean(!recording && videoBlob && isUploading)}
        onClose={onCloseUploadModal}
        centered
        withCloseButton={false}
        lockScroll={false}
      >
        <div className="centered-stack">
          <h4>Uploading Video Review</h4>
          <Progress
            value={uploadProgress.progress * 100}
            striped
            animated
            color="green"
            size="lg"
            w="70%"
          />
          {uploadProgress.estimated !== undefined &&
            uploadProgress.estimated !== 0 && (
              <Text size="xs" fs="italic">
                Estimated Seconds Left: {Math.round(uploadProgress.estimated)}
              </Text>
            )}
        </div>
      </Modal>
    );
  }

  function StartRecordingBtn() {
    return (
      <Button
        size="md"
        leftSection={<IconVideo />}
        color="green"
        radius="md"
        my={30}
        onClick={() => {
          startRecording();
        }}
      >
        {error && (error as any) == 'permissions'
          ? 'Upload a video'
          : 'Start Recording'}
      </Button>
    );
  }

  function NoCameraUploadBtn() {
    return (
      <FileButton
        accept="video/*"
        capture="user"
        multiple={false}
        onChange={(file) => {
          onMobileSubmit(file);
        }}
      >
        {(props) => (
          <Stack gap={'xs'}>
            <Button
              leftSection={<IconVideo />}
              color="green"
              size="lg"
              radius="lg"
              my={30}
              {...props}
            >
              {error && error == 'permissions'
                ? 'Upload a video'
                : 'Start Recording'}
            </Button>
          </Stack>
        )}
      </FileButton>
    );
  }

  function StopRecordingBtn() {
    return (
        <Button
          size="lg"
          color="red"
          radius="lg"
          onClick={() => mediaRecorder.current?.stop()}
          leftSection={<IconVideoOff />}
        >
          Stop Recording
        </Button>
    );
  }

  function UploadVideoBtn() {
    const [videoOrientationClass, setVideoOrientationClass] = useState(
      'video-window-portrait',
    );

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.addEventListener(
          'loadedmetadata',
          setVideoOrientation,
        );
      }
    }, [videoRef.current]);

    function setVideoOrientation() {
      if (videoRef.current) {
        if (videoRef.current.videoHeight < videoRef.current.videoWidth) {
          setVideoOrientationClass('video-window-landscape');
        } else {
          setVideoOrientationClass('video-window-portrait');
        }
      }
    }

    function getSrcFromBlob() {
      if (videoBlob) {
        let URL = window.URL || window.webkitURL;
        return URL.createObjectURL(videoBlob);
      } else {
        return '';
      }
    }

    const showVideo = (isMobile && uploadProgress.progress == 0) || (!isMobile && error && error == 'permissions');

    return (
      <Stack mb={20} maw="100%">
        {showVideo && <video
          controls={!!videoBlob}
          ref={videoRef}
          className={videoOrientationClass}
          /*style={{ height: error == 'permissions' ? 0 : 'auto' }}*/
          autoPlay
          muted
          playsInline
          src={getSrcFromBlob()}
        />}
        <Flex justify={'center'} align={'center'} gap={'xs'} wrap={'nowrap'}>
          <Button
            size="lg"
            leftSection={<IconRetry />}
            color="red"
            radius="md"
            onClick={() => handleRetake()}
          >
            Retake
          </Button>
          <Button
            size="lg"
            leftSection={<IconVideo />}
            color="green"
            radius="md"
            onClick={() => videoBlob && onSubmit(videoBlob)}
          >
            Upload
          </Button>
        </Flex>
      </Stack>
    );
  }

  function ButtonViewState({ recording, videoBlob, isMobile, error, setShowLegal }: {recording: boolean, videoBlob: Blob|null, isMobile:boolean, error:VideoErrorType, setShowLegal:React.Dispatch<React.SetStateAction<boolean>>}) {
    useEffect(() => {
      if (!recording && !videoBlob) {
        setShowLegal(false);
      } else if (videoBlob) {
        setShowLegal(true);
      }
    }, [recording, videoBlob, setShowLegal]);
  
    if (recording) {
      return (<StopRecordingBtn />);
    } else if (!recording && !videoBlob) {
      if (isMobile || error === "permissions") {
        return (<NoCameraUploadBtn />);
      } else {
        return (<StartRecordingBtn />);
      }
    } else {
      return (<UploadVideoBtn />);
    }
  }
  
  function FooterText(){
    if(error && error == 'permissions') {
      return (
      <Text size={'sm'} py={'sm'} color={'gray.9'}>
        You have not gave permission to use the camera. Please check the top
        left of the browser near the URL.
        <br />
        Alternatively, you can upload a video file above.
      </Text>
      )
    }else{
      return (<></>)
    }
  }

  function LegalText(){
    return(
      <p className="legal-jargon">
      {legalJargon}
    </p>
    )
  }

  return (
    <div className="centered-stack">
      <VideoError
        errorType={error}
        opened={videoErrorOpen}
        onClose={() => {
          setVideoErrorOpen(false);
        }}
      />

      <UploadModal />

      <Modal
        title={
          <Text fw={800} size={'lg'}>
            Camera Permissions granted
          </Text>
        }
        opened={refreshPage}
        onClose={() => {
          setRefreshPage(false);
        }}
        withCloseButton
        closeOnClickOutside
        closeOnEscape
      >
        <Text size={'lg'}>
          <IconCircleCheck
            size={19}
            color={'green'}
            style={{ verticalAlign: 'middle' }}
          />
          Permissions have just been granted! Please reload the page to see the
          changes.
        </Text>
      </Modal>

      <RatingChangeBox rating={currentRating} onChange={setCurrentRating} />
      {!isMobile &&
        <video
          controls={!!videoBlob}
          ref={videoRef}
          className={'video-window-landscape'}
          style={{ height: error == 'permissions' ? 0 : 'auto' }}
          autoPlay
          muted
        />
      }
      {loading && !isMobile && <Title>Awaiting Video Permissions...</Title>}

      <ButtonViewState 
        recording={recording} 
        videoBlob={videoBlob} 
        isMobile={isMobile}
        error={error} 
        setShowLegal={setShowLegal} 
      />
      {showLegal && <LegalText />}
      <FooterText />
    </div>
  );
}
